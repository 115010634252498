@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ranga&display=swap");

$font-family: "Noto Sans", sans-serif;
$logo-font: "Ranga", cursive;
$primary_color: #b0c364;
$second_color: #787879;
$max-width: 1200px;

@mixin flex-center($xy, $direction, $wrap) {
    display: flex;
    @if ($xy == y) {
        justify-content: center;
    } @else if ($xy == x) {
        align-items: center;
    } @else {
        justify-content: center;
        align-items: center;
    }
    flex-direction: $direction;
    flex-wrap: $wrap;
}

@mixin translate-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin grid-center($justifyContent, $alignContent) {
    display: grid;
    justify-content: $justifyContent;
    align-content: $alignContent;
}

