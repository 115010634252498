@import "../../assets/styles/_global.scss";


.BlogBody {
    display: flex;
    max-width: 1200px;
    flex-wrap: wrap;
    gap: 2em;
    padding: 0 2em;

    .blog-left {
        flex: 3.2;
    }

    .blog-right {
        flex: 1;
    }
}


@media only screen and (max-width: 1199px) {
    .BlogBody {
        max-width: 992px;
        
        .blog-left {
            flex: 2.4;
        }
    
        .blog-right {
            flex: 1;
        }
    }
}

@media only screen and (max-width: 991px) {
    .BlogBody {
        max-width: 768px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .blog-right {
            max-width: 330px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .BlogBody {
        max-width: 100%;
    }
}