@import "../../assets/styles/_global.scss";

.Post_Container {
    max-width: 100%;

    .row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 100%;
        margin: 5em -3em;

        .image-container {
            max-width: 30em;
            max-height: 30em;
            margin: 2em;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .details {
            max-width: 35em;
            text-align: center;
            margin: 2em;
        }

        button {
            background-color: $primary_color;
            padding: 15px 32px;
            text-align: center;
            cursor: pointer;
            border: none;
            color: white;
            transition: all .8s;

            &:hover {
                background-color: black;
            }
        }
    }
}

.Post_Container {
    font-family: "Roboto", sans-serif;
    h3 {
        font-weight: bold;

        &:hover {
            color: $primary_color;
        }
    }

    h5 {
        color: $primary_color;
        text-transform: uppercase;
        font-size: 0.8em;
        line-height: 3;
    }

    h6 {
        text-transform: uppercase;
        font-size: 0.8em;
        font-weight: bold;
        line-height: 3;
    }

    h6,
    p {
        color: $second_color;
    }
}

@media only screen and (max-width: 1200px) {
    .Post_Container {
        .row {
            margin: 0;
        }
    }
}
