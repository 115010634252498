@import "../../assets/styles/_global.scss";

// Header Image CSS
.header {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url(../../assets/images/nav-img.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 7em;
  max-width: 100%;

  .header-top {
    color: white;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    // Header Icons CSS
    .header-icons {
      margin-top: 3em;

      a {
        color: white;
        margin-right: 20px;
      }

      a:hover {
        color: $primary_color;
      }
    }

    // Searchbar CSS
    .navbar-search {
      margin-top: 2em;

      .search-bar {
        position: relative;
        max-width: 24em;
        border-bottom: 2px solid gray;

        input[type="text"] {
          color: white;
          caret-color: white;
          border: none;
          font-weight: lighter;
          background: transparent;
          padding-bottom: 0.8em;
          padding-top: 0.5em;
        }

        input[type="text"]:focus {
          border-color: white;
          outline: none;
        }

        input[type="text"]::placeholder {
          font-style: italic;
          color: rgb(192, 192, 192);
        }

        button {
          background: transparent;
          border: none;
          color: white;
        }
      }
    }
  }
}

// Navbar Title CSS
.navbar-header-text {
  max-width: 100%;

  .navbar-header-text-p1 {
    text-align: center;
    font-size: 7em;
    font-family: $logo-font;
  }

  .navbar-header-text-p2 {
    text-align: center;
    margin-top: -2.5em;
    margin-left: 7em;
    color: $second_color;
    font-family: $logo-font;
    font-size: 1.8em;
  }
}

// Navbar List CSS
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  margin-top: 60px;
  max-width: 1290px;
  margin: 0 auto;
  
  main {
    display: flex;
    justify-content: space-between;
    width: 60%;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      color: unset;
      
      li {
        display: inline-block;
        position: relative;
        a {
          text-decoration: none;
          display: block;
          color: black;
          font-weight: 600;
          text-align: center;
          padding: 14px 16px;
        }
        
        a:hover {
          color: $primary_color;
          text-decoration: none;
        }

        // Dropdown Menu
        .dropdown {
          position: absolute;
          transition: all .5s;
          opacity: 0;
          visibility: hidden;
          left: 0;
          top: 300%;
          background-color: $primary_color;

          ul {
            width: 150px;

            li {
              width: 100%;
              a:hover {
                background-color: black;
                color: white;
              }
            }
          }
        }

        .show{
          top: 150%;
          opacity: 1;
          visibility: visible;
        }
      }

      .pages:hover{
        color: $primary_color;
      }
    }
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
  }

  #login {
    color: grey;
  }

  #login:hover {
    color: $primary_color;
  }
}

// media query starts

@media only screen and (max-width: 674px) {
  .navbar-icons {
    margin-top: 0;
  }
  .navbar-search {
    margin-bottom: 3em;
  }
}

@media only screen and (max-width: 366px) {
  .navbar_img .navbar-top .navbar-icons {
    margin-top: 3em;
    margin-left: 1em;
  }

  input[type="text"] {
    width: 12em;
  }

  .navbar-header-text .navbar-header-text-p2 {
    text-align: center;
    margin-top: -2.5em;
    margin-left: 7em;
    color: #989899;
    font-family: "Ranga", cursive;
    font-size: 1.2em;
  }
}
