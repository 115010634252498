@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
@import "../../assets/styles/_global.scss";

.Header_Container {
    @include flex-center(xy, row, wrap);
    font-family: $font-family;
    padding: 120px 0 80px 0;
    gap: 3em;
 
    .Item {
        position: relative;
        height: 20em;
        width: 23em;
        transition: 0.2s;

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        }

        &:hover .absolute {
            background: $primary_color;
            border: 2px solid $primary_color;
            outline: 2px solid white;
        }

        &:hover .absolute > p {
            color: white;
        }

        &:hover .absolute > h6 {
            color: white;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center
        }

        .absolute {
            @include flex-center(xy, column, nowrap);
            user-select: none;
            width: 19.5em;
            height: 8.2em;
            background: white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            border: 2px solid white;
            outline: 2px solid $primary_color;
            outline-offset: -15px;
            color: white;
            transition: 0.4s;

            h6 {
                color: $primary_color;
                text-transform: uppercase;
                padding-top: 1em;
                font-weight: bold;
                font-size: 14px;
                transition: 0.2s;
                
                &:hover {
                    cursor: pointer;
                }
            }

            p {
                font-size: 24px;
                font-weight: bold;
                line-height: 1em;
                color: black;
                transition: 0.2s;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

@media (max-width: 373px) {
    .Header_Container {
        width: 100%;
        
        .Item {
            width: 80%;
            height: 30vh;

            .absolute {
                width: 80%;
            }
        }
    }
}
