@import "../../assets/styles/_global.scss";

.Blog_Container {
  @include grid-center(center, center);

  .Container {
    font-family: "Roboto", sans-serif;
    max-width: $max-width;

    .Left-Container {
      display: grid;
      row-gap: 1.7em;

      .card-left-parent {
        .left-portion {
          img {
            width: 100%;
            max-height: 18em;
            object-fit: cover;
            object-position: center;
          }
        }

        .right-portion {
          display: grid;
          grid-template-rows: 1fr 1fr 1fr 3fr;

          h3 {
            font-weight: bold;
            &:hover {
              color: $primary_color;
            }
          }

          h5 {
            color: $primary_color;
            text-transform: uppercase;
            font-size: 0.87em;
            line-height: 3;
            font-weight: bold;
          }

          h6 {
            text-transform: uppercase;
            font-size: 0.87em;
            font-weight: bold;
          }

          h6,
          p {
            color: $second_color;
          }
        }
      }
    }

    .Right-Container {
      @include grid-center(center, center);
      grid-template-rows: auto;
      row-gap: 1.6em;

      .abs-div {
        position: relative;
        background-color: black;

        span {
          @include translate-center();
          @include flex-center(xy, none, none);
          position: absolute;
          background-color: #000000;
          opacity: 0.6;
          color: white;
          font-size: 1.25em;
          font-style: italic;
        }

        img {
          object-fit: cover;
          opacity: 0.6;
        }
      }

      .cards {
        display: grid;
        grid-template-rows: auto;
        row-gap: 2em;

        .card-right-parent {
          display: grid;
          grid-template-columns: 4.8em 3fr;
          column-gap: 1em;
          .left-portion {
            img {
              width: 100%;
              height: 5em;
              object-fit: cover;
              object-position: center;
            }
          }
          .right-portion {
            h3 {
              font-size: 1em;
              font-weight: bold;
            }
            h6 {
              font-size: 0.8em;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 993px) {
  .Container {
    display: grid;
    grid-template-columns: 75% 22%;
    column-gap: 2em;
  }
}

@media only screen and (max-width: 992px) {
  .Container {
    display: grid;
    grid-template-rows: auto;
    row-gap: 3em;
  }
}

@media only screen and (max-width: 1200px) {
  .Container {
    padding: 0 2em;
  }
}

@media only screen and (min-width: 370px) {
  .Container {
    .Left-Container {
      .card-left-parent {
        display: flex;
        flex-direction: column;
      }
    }

    .Right-Container {
      .abs-div {
        span {
          width: 7em;
          height: 6em;
        }

        img {
          width: 16.5em;
          height: 23.8em;
        }
      }
    }
  }
}

@media only screen and (min-width: 570px) {
  .Container {
    .Left-Container {
      .card-left-parent {
        display: grid;
        grid-template-columns: 1.2fr 2fr;
        column-gap: 1.7em;
      }
    }
  }
}

@media only screen and (max-width: 370px) {
  .Container {
    .Left-Container {
      .card-left-parent {
        .left-portion {
          img {
            display: none;
          }
        }
      }
    }

    .Right-Container {
      .abs-div {
        span {
          width: 50%;
          height: 4em;
        }

        img {
          width: 100%;
          height: 20.8em;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .Container {
    .Left-Container {
      .card-left-parent {
        display: grid;
        grid-template-columns: 1.2fr 2fr;
        column-gap: 1.7em;
      }
    }
  }
}
