.Gallery_Container {
  margin-top: 5em;
  margin-bottom: 1em;
  width: 100%;

  .img {
    transition: all 1s;

    &:hover {
      transform: scale(1.1);
    }
  }

  .gallery {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    .pics {
      -webkit-transition: all 350ms ease;
      transition: all 350ms ease;
      overflow: hidden;

      .images {
        width: 100%;
        object-fit: cover;
        transition: transform 0.5s;

        .mod {
          background-color: rgba(0, 0, 0, 0.634);
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          visibility: hidden;
          transition: all 0.5s;

          .plus {
            font-size: 30px;
            cursor: pointer;
            width: 50px;
            height: 50px;
            color: white;
            background-color: #b0c364;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(40px);
            transition: all .7s;
          }
        }
        &:hover {
          transform: scale(1.1);
          background-image: unset;
        }

        &:hover .mod {
          opacity: 1;
          visibility: visible;
        }

        &:hover .plus{
            transform: translateY(0);
        }
      }
    }
  }
}

// Model CSS

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  padding: 20px 0 20px;
  margin: 0 auto;
}

.model.open svg {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  cursor: pointer;
}

@media only screen and (min-width: 7681px) {
  .images {
    height: 770px;
  }
}

@media only screen and (max-width: 7680px) {
  .images {
    height: 630px;
  }
}

@media only screen and (max-width: 3800px) {
  .images {
    height: 550px;
  }
}

@media only screen and (max-width: 2500px) {
  .images {
    height: 360px;
  }
}

@media only screen and (max-width: 1900px) {
  .images {
    height: 340px;
  }
}

@media only screen and (max-width: 1700px) {
  .images {
    height: 310px;
  }
}

@media only screen and (max-width: 1600px) {
  .images {
    height: 240px;
  }
}

@media only screen and (max-width: 1400px) {
  .images {
    height: 210px;
  }
}

@media only screen and (max-width: 1300px) {
  .images {
    height: 195px;
  }
}

@media only screen and (max-width: 1200px) {
  .images {
    height: 180px;
  }
}

@media only screen and (max-width: 1000px) {
  .images {
    height: 150px;
  }
}

@media only screen and (max-width: 990px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  .images {
    height: 260px;
  }
}

@media only screen and (max-width: 800px) {
  .images {
    height: 220px;
  }
}

@media only screen and (max-width: 576px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .images {
    height: 260px;
  }
}

@media only screen and (max-width: 576px) {
  .images {
    height: 260px;
  }
}

@media only screen and (max-width: 400px) {
  .images {
    height: 160px;
  }
}

@media only screen and (max-width: 282px) {
  .images {
    height: 100px;
  }
}

@media only screen and (max-width: 250px) {
  .images {
    height: 80px;
  }
}
